#menu__main .nav-link {
    padding-top: 0;
    color: #F5AA7C;
}

#menu__main a:hover.nav-link {
    color: #DE8366;
}

#menu__main a.nav-link.active {
    color: #FA8073;
}

section#main__body {
    min-height: 600px;
}

.form-control {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #f5aa7c2e;
}

.btn {
    border-radius: 0;
}

.btn-primary {
    background-color: #F5AA7C;
    border-color: #F5AA7C;
}

.btn-outline-primary {
    border-color: #F5AA7C;
    color: #F5AA7C;
}

.btn-outline-primary:hover {
    border-color: #DE8366;
    background-color: #DE8366;
    color: #ffffff;
}

.text-primary {
    color: #F5AA7C !important;
}

a:hover.text-primary {
    color: #DE8366 !important;
}

legend {
    font-size: 12px;
    color: #666666;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 20px;
}

.body__content {
    margin-top: 30px;
}

.icon__create {
    margin-left: 15px;
    font-size: 42px;
}

table thead tr th, table tbody tr td {
    text-align:center;
    font-size: 14px;
}